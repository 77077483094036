import { Button, ICONS, Icon } from '@mvr/ui'
import { ROUTES } from 'constants/routes'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useVerificationCenters } from 'hooks'

import { FormButtonsContainer } from './CreateNewFormButtons.styles'

interface CreateNewFormButtonsProps {
  onClick?: () => void
}

export const CreateNewFormButtons = ({ onClick }: CreateNewFormButtonsProps) => {
  const navigate = useNavigate()
  const { isFirstRegistration, isPostRegistration, isFirstAndPostRegistration } = useVerificationCenters()

  const handleFormCreateClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const formKind = e.currentTarget.getAttribute('data-form')

      navigate(`${ROUTES.form}?kind=${formKind}`)

      if (onClick) {
        onClick()
      }
    },
    [navigate],
  )

  return (
    <FormButtonsContainer>
      {isFirstRegistration || isFirstAndPostRegistration ? (
        <Button onClick={handleFormCreateClick} data-form='first' title='First verification form'>
          <Icon icon={ICONS.PLUS} />
          First ver.
        </Button>
      ) : null}

      {isPostRegistration || isFirstAndPostRegistration ? (
        <Button onClick={handleFormCreateClick} data-form='post' title='Post verification form'>
          <Icon icon={ICONS.PLUS} />
          Post ver.
        </Button>
      ) : null}
    </FormButtonsContainer>
  )
}
