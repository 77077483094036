import { getYear } from 'date-fns'
import map from 'lodash/map'

export type Dictionary = {
  [key: string]: string
}

export type Dictionaries = {
  [key: string]: Dictionary
}

export const dictionaryToOptions = (dictionary: any) =>
  map(dictionary, (label: string, value: string) => ({
    label,
    value,
  }))

export const arrayToOptions = (dictionary: any) =>
  map(dictionary, (value: any) => ({
    label: value.name,
    value: value.uuid,
  }))

export const phoneStringToNumber = (phoneNumber: string) => phoneNumber.replace(/^\+/g, '')

const currentYear = getYear(new Date())

// From 1960 to current year
export const yearsFrom1960 = new Array(currentYear - 1959).fill(0).map((_, index) => ({
  label: `${currentYear - index}`,
  value: `${currentYear - index}`,
}))
