import { FormikInput, FormikSelect, Heading2, Row } from '@mvr/ui'
import { useFormikContext } from 'formik'
import { arrayToOptions } from 'utils/data'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

type EntryInfoFirstProps = {
  dictionaries: any
}

export const EntryInfoFirst = ({ dictionaries }: EntryInfoFirstProps) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const { centers, customAgents } = dictionaries

  const handleChangeCenter = (addressFieldName: string, arr: any[]) => (value: string) => {
    const item = arr.find((item: any) => item.uuid === value)

    if (!value || !item) {
      return setFieldValue(addressFieldName, undefined)
    }

    const address = `${item?.address || ''} ${item.city || ''} ${item.country.name || ''}`
    return setFieldValue(addressFieldName, address)
  }

  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Entry Info</Heading2>
      </CardHeading>

      <Row>
        <FormikInput name='entry_number' placeholder='Entry Number' autoComplete='off' required />
      </Row>

      <InputRow>
        <FormikSelect
          name='verification_center_uuid'
          placeholder='Verification Centre'
          options={arrayToOptions(centers)}
          onChange={handleChangeCenter('verification_center_uuid_address', centers)}
          disabled={centers.length === 1}
          searchable
          required
        />

        {values.verification_center_uuid && (
          <FormikInput
            name='verification_center_uuid_address'
            disabled
            placeholder='Verification Centre Address'
            autoComplete='off'
          />
        )}
      </InputRow>

      <InputRow>
        <FormikSelect
          name='customs_agent_uuid'
          placeholder='Customs Agent'
          options={arrayToOptions(customAgents)}
          onChange={handleChangeCenter('customs_agent_uuid_address', customAgents)}
          disabled={customAgents.length === 1}
          searchable
          required
        />

        {values.customs_agent_uuid && (
          <FormikInput
            name='customs_agent_uuid_address'
            disabled
            placeholder='Customs Agent Address'
            autoComplete='off'
          />
        )}
      </InputRow>
    </FormContainer>
  )
}
