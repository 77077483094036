import { FormikValues } from 'formik'
import get from 'lodash/get'
import head from 'lodash/head'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { paths } from '../../api'
import { useCustomAgents, useFetch, useLocalStorage, useVerificationCenters } from '../../hooks'
import { useAlert } from '../../services'

import { CustomAgentProps, VerificationCenterProps } from '../../common'

import { ROUTES } from '../../constants/routes'
import { getSteps } from './steps'
import { valuesToData } from './utils/utils'

const initialSchema = Yup.object()

export const useForm = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [created, setCreated] = useState(null)
  const [partialFormValues, setPartialFormValues] = useState<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { centers, isLoading: isLoadingCenters, ...centersMeta } = useVerificationCenters()
  const { customAgents } = useCustomAgents()
  const center: VerificationCenterProps = head(centers)
  const customAgent: CustomAgentProps = head(customAgents)
  const alert = useAlert()
  const { useGetQuery, usePostMutation } = useFetch()

  const { setLocalStorageStateValue: setVerificationFormValue } = useLocalStorage('verification-form')
  const { localStorageValue: activeStepValue, setLocalStorageStateValue: setActiveStepValue } =
    useLocalStorage('form-active-step')

  const steps = getSteps(centersMeta)
  const isFirstStep = !activeStep
  const isLastStep = activeStep === steps.length - 1
  const formsPath = paths.forms(centersMeta)
  const formsMutation = usePostMutation([formsPath], null, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const formsDictionariesPath = paths.formsDictionaries()
  const dictionariesQuery = useGetQuery([formsDictionariesPath], formsDictionariesPath)
  const { data: dictionaries } = dictionariesQuery.data?.data || ({} as any)
  const validationSchema = steps
    .slice(0, activeStep + 1)
    .reduce((acc, { validationSchema }) => (validationSchema ? acc.concat(validationSchema) : acc), initialSchema)

  useEffect(() => {
    if (centers.length === 1) {
      setPartialFormValues((prevValues: FormikValues) => ({
        ...prevValues,
        verification_center_uuid: center?.uuid,
        verification_center_uuid_address: center
          ? `${center.address || ''} ${center.city || ''} ${center.country?.name || ''}`
          : '',
      }))
    }
  }, [JSON.stringify(center), centers.length])

  useEffect(() => {
    if (customAgents.length === 1) {
      setPartialFormValues((prevValues: FormikValues) => ({
        ...prevValues,
        customs_agent_uuid: customAgent?.uuid,
        customs_agent_uuid_address: customAgents[0]
          ? `${customAgent.address || ''} ${customAgent.city || ''} ${customAgent.country?.name || ''}`
          : '',
      }))
    }
  }, [JSON.stringify(customAgent), customAgents.length])

  useEffect(() => {
    if (formsMutation.isLoading) {
      alert.hideAlert()
    }
  }, [formsMutation.isLoading])

  useEffect(() => {
    if (formsMutation.isError) {
      alert.showAlert({
        type: 'error',
        text: get(formsMutation.error, 'response.data.message', 'Server error'),
      })
    }
  }, [formsMutation.isError])

  useEffect(() => {
    const storedActiveStep = Number(activeStepValue)
    setActiveStep(storedActiveStep ? storedActiveStep : 0)
  }, [])

  useEffect(() => {
    if (created) {
      setActiveStep(0)
      setActiveStepValue(0)
    } else {
      setActiveStepValue(activeStep)
    }
  }, [activeStep, setActiveStepValue, isLastStep])

  const updateStep = () => {
    setActiveStep((prevActiveStep) => (isLastStep ? 0 : prevActiveStep + 1))
  }

  const handleCancel = () => {
    if (isFirstStep) {
      setVerificationFormValue(null)
      setActiveStepValue(null)
      navigate(ROUTES.home)

      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true)

    if (isLastStep) {
      try {
        const body = valuesToData(values)
        const response = await formsMutation.mutateAsync({ path: formsPath, body })

        setCreated((response?.data as any)?.data)
      } catch (err: any) {
        console.log(err)
      }

      setIsSubmitting(false)

      return
    }

    setPartialFormValues({
      ...partialFormValues,
    })

    updateStep()
    setIsSubmitting(false)
  }

  const handleCloseSubmittedPanel = () => {
    setCreated(null)
    setActiveStep(0)
  }

  return {
    isSubmitting,
    steps,
    activeStep,
    customAgents,
    isLoadingCenters,
    centers,
    created,
    partialFormValues,
    dictionaries,
    isFirstStep,
    isLastStep,
    validationSchema,
    alert,
    handleCancel,
    handleSubmit,
    handleCloseSubmittedPanel,
  }
}

export default useForm
