import { useMedia } from '@mvr/ui';
import { useFormikContext } from 'formik';
import { camelCase, capitalize, isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { scrollTo } from 'utils/animations';

export const useActions = (step: number) => {
  const { isSubmitting, errors } = useFormikContext();
  const stepRef = useRef(step);
  const { isMobile, isTablet } = useMedia();

  useEffect(() => {
    scrollTo(0);
  }, [step]);

  useEffect(() => {
    stepRef.current = step;
  }, [step]);

  const getFileUploadOffset = () => {
    if (isTablet) {
      return -170;
    } else if (isMobile) {
      return -10;
    } else {
      return -200;
    }
  };

  const scrollToFirstError = () => {
    setTimeout(() => {
      if (stepRef.current === step && !isEmpty(errors)) {
        const errorValues = Object.values(errors);
        const firstError = errorValues.find((error) => error);

        if (firstError) {
          const fieldName = Object.keys(errors).find((key) => errors[key] === firstError);
          const errorField = document.querySelector(`[name='${fieldName}']`);
          const offset = errorField.getAttribute('type') === 'file' ? getFileUploadOffset() : -70;
          // @TODO: consider to go away from using test-ids for creating logic (change in lib)
          const errorElement = document.querySelector(
            `[data-testid='test${capitalize(camelCase(fieldName))}ErrorBlock']`
          );

          if (errorElement) {
            scrollTo(errorElement, offset);
          }
        }
      }
    }, 100);
  };

  const nextClickHandle = () => {
    if (!isEmpty(errors)) {
      scrollToFirstError();
    } else {
      if (stepRef.current !== 3) {
        scrollTo(0);
      }
    }
  };

  return {
    isSubmitting,
    nextClickHandle,
  };
};
