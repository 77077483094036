import { formatErrorMessage } from 'locale'
import { cachePreviousValidation, isVinUniqueQuery } from 'utils/validation'
import * as Yup from 'yup'

export const VehicleDetailsVinExistsValidationSchema = Yup.object().shape({
  vin: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'VIN / Chassis No.' }))
    .vin({ fieldName: 'VIN / Chassis No.' })
    .test(
      'asyncTest',
      formatErrorMessage('uniqueness', { fieldName: 'VIN / Chassis No.' }),
      cachePreviousValidation('', isVinUniqueQuery),
    ),
})

export const VehicleDetailsValidationSchema = Yup.object().shape({
  make_uuid: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Make' })),

  vin: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'VIN / Chassis No.' }))
    .vin({ fieldName: 'VIN / Chassis No.' }),
})
