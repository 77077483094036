import { Heading2, LogoMVV } from '@mvr/ui'
import React, { Component, ReactNode } from 'react'

import { ErrorContainer } from './ErrorBoundary.styles'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <LogoMVV width={205.71} height={120} />
          <Heading2>Some error occurred. Please reload the page and try again.</Heading2>
        </ErrorContainer>
      )
    }

    return this.props.children
  }
}
