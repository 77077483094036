import { Form, Heading2, Loader, Pagination, SearchInput, Table, Text, lightTheme } from '@mvr/ui'
import { useState } from 'react'

import { ArchiveWrapper, LoadingWrapper, NoResults, PaginationWrapper, Paper, SearchWrapper } from './Archive.styles'
import { MESSAGE_TYPES } from './constants/messageTypes'
import { PAGINATION_ITEMS } from './constants/paginationItems'
import useArchiveData from './useArchiveData'

export const Archive = (): JSX.Element => {
  const [searchValue, setSearchValue] = useState('')
  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const { forms, isLoading, isError, isNoResults, isNotFound, total } = useArchiveData(searchValue)

  const formsToShow = [...forms.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)]
  let message = null

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader size={24} />
      </LoadingWrapper>
    )
  }

  if (isError) {
    message = MESSAGE_TYPES.isError
  } else if (isNoResults) {
    message = MESSAGE_TYPES.isNoResults
  } else if (isNotFound) {
    message = MESSAGE_TYPES.isNotFound
  }

  return (
    <ArchiveWrapper>
      <Paper>
        {isError || isNotFound ? (
          <NoResults>
            <Heading2>{message.title}</Heading2>
            <Text>{message.text}</Text>
          </NoResults>
        ) : (
          <>
            <Form initialValues={{ search: searchValue }} onSubmit={() => undefined}>
              <SearchWrapper>
                <SearchInput
                  name='search'
                  placeholder='Search by VIN'
                  className='test'
                  onChange={(value: string) => setSearchValue(value)}
                />
              </SearchWrapper>
            </Form>

            {isNoResults ? (
              <NoResults>
                <Heading2>{message.title}</Heading2>
                <Text>{message.text}</Text>
              </NoResults>
            ) : (
              <>
                <Table
                  columns={['VIN', 'Created at', 'Form number', 'Type']}
                  rows={formsToShow}
                  collapseOnMedia={lightTheme.breakPoints.md}
                />

                <PaginationWrapper>
                  <Pagination
                    onPageChange={(newPage) => setPage(newPage)}
                    page={page}
                    count={total}
                    itemsPerPageOptions={PAGINATION_ITEMS}
                    itemsPerPage={itemsPerPage}
                    onItemsPerPageChange={(items) => {
                      setItemsPerPage(items)
                      setPage(0)
                    }}
                  />
                </PaginationWrapper>
              </>
            )}
          </>
        )}
      </Paper>
    </ArchiveWrapper>
  )
}
