import { isEqual, omit } from 'lodash'
import { useEffect, useState } from 'react'

import { paths } from 'api'
import { useFetch } from 'hooks'

import { UserInfoQueryProps, UserProps } from 'common'

type FromValuesProps = Omit<UserProps, 'id' | 'role' | 'entity'>

export const usePersonalInfo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formValues, setFormValues] = useState<FromValuesProps>()
  const { usePostMutation, useGetQuery } = useFetch()
  const userInfoPath = paths.userInfo()
  const changeUserInfoMutation = usePostMutation([userInfoPath])
  const getUserInfo = useGetQuery<UserInfoQueryProps>([userInfoPath], userInfoPath)
  const { data: userInfo } = getUserInfo.data?.data || {}
  const { name, phone, email } = userInfo || {}

  useEffect(() => {
    if (getUserInfo.isSuccess) {
      setFormValues({
        name,
        phone,
        email,
      })
    }
  }, [JSON.stringify(getUserInfo)])

  const handleSubmit = (formData: FromValuesProps) => {
    const hasEmailChanged = formData.email !== formValues.email

    if (!isEqual(formValues, formData) && !hasEmailChanged) {
      changeUserInfoMutation.mutate(
        { path: paths.userInfo(), body: omit(formData, ['email']) },
        {
          onSuccess: () => setIsModalOpen(true),
        },
      )
    }
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  return {
    isModalOpen,
    onModalClose,
    formValues,
    handleSubmit,
  }
}
