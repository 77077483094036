import styled from 'styled-components'

export const ArchiveWrapper = styled.div`
  padding-bottom: 3rem;
  margin-top: 1.5rem;
`

export const Paper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 1.5rem;

  ${({ theme }) => `
    background: ${theme.palette.windowBackground};
    border-radius: ${theme.shape.borderRadius};
    
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 1rem;
    }
  `}
`

export const SearchWrapper = styled.div`
  max-width: 41%;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      max-width: 50%;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      max-width: 100%;
    }
  `}
`

export const NoResults = styled.div`
  display: flex;
  min-height: 25.5rem;
  max-width: 17.5rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin: auto;
  text-align: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      min-height: 11.5rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      min-height: auto;
      padding: 1rem 0;
    }
  `}

  & > div:last-child {
    color: ${({ theme }) => theme.palette.contrastMedium};
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 25.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      min-height: 11.5rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      min-height: auto;
      padding: 1rem 0;
    }
  `}
`

export const PaginationWrapper = styled.div`
  margin-top: 1.5rem;
`
