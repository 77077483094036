import * as Yup from 'yup'

import { BodyDetails } from './BodyDetails/BodyDetails'
import { BodyDetailsValidationSchema } from './BodyDetails/BodyDetails.validation'
import { EngineDetails } from './EngineDetails/EngineDetails'
import {
  EngineDetailsEngineNumberExistsValidationSchema,
  EngineDetailsValidationSchema,
} from './EngineDetails/EngineDetails.validation'
import { TyreDetails } from './TyreDetails/TyreDetails'
import { TyreDetailsValidationSchema } from './TyreDetails/TyreDetails.validation'
import { VehicleDetails as VehicleDetailsSection } from './VehicleDetails/VehicleDetails'
import {
  VehicleDetailsValidationSchema as VehicleDetailsSectionValidationSchema,
  VehicleDetailsVinExistsValidationSchema,
} from './VehicleDetails/VehicleDetails.validation'
import { VehicleHistory } from './VehicleHistory/VehicleHistory'
import { VehicleHistoryValidationSchema as VehicleHistorySectionValidationSchema } from './VehicleHistory/VehicleHistory.validation'

export const FirstVehicleDetailsValidationSchema = Yup.object()
  .concat(VehicleDetailsSectionValidationSchema)
  .concat(VehicleHistorySectionValidationSchema)
  .concat(BodyDetailsValidationSchema)
  .concat(TyreDetailsValidationSchema)
  .concat(EngineDetailsValidationSchema)
  .concat(VehicleDetailsVinExistsValidationSchema)
  .concat(EngineDetailsEngineNumberExistsValidationSchema)

export const PostVehicleDetailsValidationSchema = Yup.object()
  .concat(VehicleDetailsSectionValidationSchema)
  .concat(VehicleHistorySectionValidationSchema)
  .concat(BodyDetailsValidationSchema)
  .concat(TyreDetailsValidationSchema)
  .concat(EngineDetailsValidationSchema)

export const VehicleDetails = (props: any) => {
  return (
    <>
      <VehicleDetailsSection {...props} />
      <VehicleHistory {...props} />
      <EngineDetails {...props} />
      <BodyDetails {...props} />
      <TyreDetails {...props} />
    </>
  )
}
