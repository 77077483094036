import { useLocalStorage } from './useLocalStorage'

export const useClearFormStorageData = () => {
  const { setLocalStorageStateValue: setVerificationFormValue } = useLocalStorage('verification-form')
  const { setLocalStorageStateValue: setFormActiveStepValue } = useLocalStorage('form-active-step')

  // @TODO: update when Submitted component will be done as SubmittedPage so that url !== 'form'
  const clearFormStorageData = () => {
    setVerificationFormValue(null)
    setFormActiveStepValue(null)
  }

  return {
    clearFormStorageData,
  }
}
