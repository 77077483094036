import { Button, Form as ChangePINForm, FormikInput, Heading2, useMedia } from '@mvr/ui'

import { FieldsWrapper, FormButton, Heading } from '../../Profile.styles'
import { SuccessModal } from '../index'
import { ChangePINValidationSchema } from './ChangePIN.validation'
import { useChangePIN } from './useChangePIN'

const ChangePIN = () => {
  const { isModalOpen, onModalClose, formValues, handleSubmit } = useChangePIN()
  const { isMobile } = useMedia()

  return (
    <div>
      <Heading>
        <Heading2>Change PIN</Heading2>
      </Heading>

      <ChangePINForm
        onSubmit={(formData, { setErrors, resetForm }) => handleSubmit(formData, setErrors, resetForm)}
        validationSchema={ChangePINValidationSchema}
        initialValues={formValues}
        enableReinitialize
      >
        <FieldsWrapper>
          <FormikInput name='current_pin_code' type='password' placeholder='Current PIN' autoComplete='off' required />

          <FormikInput name='new_pin_code' type='password' placeholder='New PIN' autoComplete='off' required />

          <FormikInput
            name='new_pin_code_confirmation'
            type='password'
            placeholder='Confirm PIN'
            autoComplete='off'
            required
          />
        </FieldsWrapper>

        <FormButton>
          <Button type='submit' fullWidth={isMobile}>
            Change PIN
          </Button>
        </FormButton>
      </ChangePINForm>

      <SuccessModal isOpen={isModalOpen} onClose={onModalClose} />
    </div>
  )
}

export default ChangePIN
