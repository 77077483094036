import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const VehicleHistoryValidationSchema = Yup.object().shape({
  mileage: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(formatErrorMessage('required', { fieldName: 'Kilometres/Mileage' }))
    .min(
      0,
      formatErrorMessage('range', {
        fieldName: 'Kilometres/Mileage',
        min: 0,
        max: '1 000 000',
      }),
    )
    .max(
      1000000,
      formatErrorMessage('range', {
        fieldName: 'Kilometres/Mileage',
        min: 0,
        max: '1 000 000',
      }),
    ),
})
