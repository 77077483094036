import { ChangePIN, PersonalInfo } from './components'

import { ProfileContainer } from './Profile.styles'

const Profile = () => {
  return (
    <ProfileContainer>
      <PersonalInfo />

      <ChangePIN />
    </ProfileContainer>
  )
}

export default Profile
