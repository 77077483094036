import { Button, Heading2, ICONS, Icon, Modal, Text, lightTheme, useMedia } from '@mvr/ui'
import { useEffect, useState } from 'react'

import { ButtonContainer, Content, ModalContainer } from './SuccessModal.styles'

interface SuccessModalProps {
  isOpen: boolean
  onClose: () => void
}

const SuccessModal = ({ isOpen, onClose }: SuccessModalProps) => {
  const { isMobile } = useMedia()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => {
    setIsModalOpen(false)
    onClose()
  }

  useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  return (
    <Modal onClose={onModalClose} isOpen={isModalOpen} maxWidth={41.81}>
      <ModalContainer>
        <Icon
          icon={ICONS.CHECK2_CIRCLE}
          color={lightTheme.palette.statusGreen}
          size={isMobile ? 64 : lightTheme.size.icon.xxl}
        />
        <Content>
          <Heading2>Great!</Heading2>
          <Text>Your changes have been successfully saved!</Text>
        </Content>
      </ModalContainer>
      <ButtonContainer>
        <Button appearance='secondary' onClick={onModalClose}>
          Ok
        </Button>
      </ButtonContainer>
    </Modal>
  )
}

export default SuccessModal
