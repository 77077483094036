import { Heading2, Loader, Modal, Step, Stepper, Form as UIForm } from '@mvr/ui'

import Actions from './components/Actions/Actions'

import { Submitted } from '../Submitted/Submitted'
import { FormWrapper, LoadingWrapper, Paper, StepperWrapper, SubmitModalContent } from './Form.styles'
import FormikPersist from './FormikPersist'
import useForm from './useForm'

export const Form = () => {
  const {
    steps,
    isSubmitting,
    activeStep,
    customAgents,
    isLoadingCenters,
    centers,
    created,
    partialFormValues,
    dictionaries,
    isFirstStep,
    isLastStep,
    validationSchema,
    alert,
    handleCancel,
    handleSubmit,
    handleCloseSubmittedPanel,
  } = useForm()

  if (isLoadingCenters || !dictionaries) {
    return (
      <LoadingWrapper>
        <Loader size={24} />
      </LoadingWrapper>
    )
  }

  const FormContent = steps[activeStep].component as (props: any) => JSX.Element

  return (
    <main>
      {created ? (
        <Submitted onClose={handleCloseSubmittedPanel} formNumber={created.number} />
      ) : (
        <FormWrapper>
          <StepperWrapper>
            <Stepper>
              {steps.map(({ title }, i: number) => (
                <Step
                  key={title}
                  active={activeStep === i}
                  completed={activeStep > i}
                  disabled={activeStep < i}
                  value={`${i + 1}`}
                  label={title}
                />
              ))}
            </Stepper>
          </StepperWrapper>

          <UIForm
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            initialValues={partialFormValues}
            enableReinitialize
          >
            <FormikPersist name='verification-form' />

            <Paper>
              <FormContent
                dictionaries={{
                  ...(dictionaries || {}),
                  centers,
                  customAgents,
                }}
              />

              {alert.renderAlert()}

              <Actions isFirstStep={isFirstStep} isLastStep={isLastStep} step={activeStep} onCancel={handleCancel} />
            </Paper>
          </UIForm>

          <Modal
            isOpen={isSubmitting}
            hasCloseButton={false}
            onClose={() => (document.body.style.overflow = '')}
            maxWidth={41.75}
          >
            <SubmitModalContent>
              <Loader size={40} />
              <Heading2>Submitting, please wait</Heading2>
            </SubmitModalContent>
          </Modal>
        </FormWrapper>
      )}
    </main>
  )
}
