import { ReactNode, createContext, useContext } from 'react'

import { IAuthentication, useAuthentication } from 'hooks'

interface IProvideAuthProps {
  children: ReactNode
}

const DEFAULT_VALUE: IAuthentication = {
  isAuthenticated: false,
} as IAuthentication

const AuthContext = createContext<IAuthentication>(DEFAULT_VALUE)

const useAuth = (): IAuthentication => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }: IProvideAuthProps) => {
  const auth = useAuthentication()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export { useAuth, AuthProvider, AuthContext }
