import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const BodyDetailsValidationSchema = Yup.object().shape({
  body_color: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Colour' })),

  vehicle_type: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Vehicle Type' })),

  vehicle_category: Yup.string()
    .trim()
    .when('vehicle_type', ([vehicleType] = [], schema) => {
      if (!vehicleType) {
        return schema
      }
      if (['ENGINEERING_PLANTS', 'TRACTOR_HEADS', 'AGRICULTURAL_TRACTORS'].includes(vehicleType)) {
        return schema.notRequired()
      } else {
        return schema.required(formatErrorMessage('required', { fieldName: 'Vehicle Category' }))
      }
    }),

  sitting_capacity: Yup.number()
    .required(formatErrorMessage('required', { fieldName: 'Sitting Capacity' }))
    .rangeNumber({
      min: 1,
      max: 999,
      fieldName: 'Sitting Capacity',
    }),

  axles_count: Yup.number()
    .required(formatErrorMessage('required', { fieldName: 'No. of Axles' }))
    .rangeNumber({
      min: 1,
      max: 99,
      fieldName: 'No. of Axles',
    }),

  hand_drive_type: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Hand Drive Type' })),

  wheel_drive_type: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Wheel Drive Type' })),
})
