export enum FieldType {
  Text = 'text',
  Image = 'image',
  Select = 'select',
  Number = 'number',
  Textarea = 'textarea',
  Radio = 'radio',
}

export enum FieldKey {
  EntryNumber = 'entry_number',
  ContainerFreightStation = 'container_freight_station',
  Importer = 'importer',
  Agent = 'agent',
  MvManufacturer = 'mv_manufacturer',
  VinNumber = 'vin_number',
  MillageRegistration = 'millage_registration',
  EngineNumber = 'engine_number',
  FuelType = 'fuel_type',
  Colour = 'colour',
  VehicleCategory = 'vehicle_category',
  NumberOfSeats = 'number_of_seats',
  NumberOfAxies = 'number_of_axies',
  HandDriveType = 'hand_drive_type',
  WheelDriveType = 'wheel_drive_type',
  NumberOfTyres = 'number_of_tyres',
  SpareWheel = 'spare_wheel',
  FrontTyresSize = 'front_tyres_size',
  RearTyresSize = 'rear_tyres_size',
  VinNumberPhoto = 'vin_number_photo',
  EngineNumberPhoto = 'engine_number_photo',
  FrontSidePhoto = 'front_side_photo',
  LeftSidePhoto = 'left_side_photo',
  RearSidePhoto = 'rear_side_photo',
  RightSidePhoto = 'right_side_photo',
  Comment = 'comment',
}

export type Component = (props: IFieldComponentProps) => JSX.Element

export interface IFieldComponentProps {
  field: IField
  index: number
  classNames?: string
  validate?: () => void
}

export interface IField {
  description: string
  tooltip: string | null
  placeholder: string | null
  key: string
  name: string
  options: ReadonlyArray<string>
  rules: FieldRules
  type: FieldType
  disabled?: boolean
}

export interface FieldRules {
  required: boolean
  max_characters: number
}

export interface IFormSubStep {
  fields: ReadonlyArray<IField>
  title: string
}

export interface IFormStep {
  index: number
  title: string
  sub_steps: ReadonlyArray<IFormSubStep>
}

export interface IFormFieldsResponse {
  data: {
    steps: ReadonlyArray<IFormStep>
  }
}

// new types!!!
export enum VerificationTypes {
  FIRST = 'FIRST_VERIFICATION',
  POST = 'POST_VERIFICATION',
}

export type VerificationType = `${VerificationTypes}`

export interface VerificationCenterProps {
  uuid: string
  name: string
  type?: string
  services?: {
    value: VerificationType
    name: string
  }[]
  country: {
    code: string
    name: string
  }
  city: string
  address: string
  district: string
  po_box?: string
}

export interface CustomAgentProps {
  uuid: string
  name: string
  country: {
    code: string
    name: string
  }
  city: string
  address: string
  district: string
  po_box?: string
}

export interface FormProps {
  created_at: string
  download_link: string
  uuid: string
  number: string
  preview_link: string
  token: string
  verify_link: string
  vin?: string
  service_type: VerificationType
}

export interface LinkProps {
  active: boolean
  label: string
  url: string | null
}

export interface FormsRawResponseProps<T> {
  data: T
  links: {
    first: 'string' | null
    last: 'string' | null
    next: 'string' | null
    prev: 'string' | null
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    links: LinkProps[]
    path: string
    per_page: number
    to: number
    total: number
  }
}
