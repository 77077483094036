import { AxiosError } from 'axios'
import { useCallback } from 'react'

import { paths } from 'api'
import { useClearFormStorageData, useFetch, useLocalStorage } from 'hooks'

import { IAuthResponce, IAuthentication, IAuthorization, LoginByCodeParams, UserProps } from 'common'

const useAuthentication = (): IAuthentication => {
  const { usePostMutation } = useFetch()
  const loginMutation = usePostMutation<IAuthResponce>(['login'])

  const { localStorageValue: authValue, setLocalStorageStateValue: setAuthValue } =
    useLocalStorage<IAuthorization>('authorization')

  const { localStorageValue: userValue, setLocalStorageStateValue: setUserValue } = useLocalStorage<UserProps>('user')
  const { clearFormStorageData } = useClearFormStorageData()

  const isExpired = useCallback((): boolean => {
    if (!authValue) {
      return true
    }

    const { expires_in } = authValue
    const now = Date.now()

    return now >= expires_in
  }, [authValue])

  const getToken = useCallback((): string | null => {
    return authValue?.token ?? null
  }, [authValue])

  const login = useCallback((value: LoginByCodeParams, onSuccess: () => void, onError: (error: AxiosError) => void) => {
    loginMutation.mutate(
      { path: paths.login(), body: value },
      {
        onSuccess: (responce) => {
          const { authorization, user } = responce.data.data

          const now = Date.now()
          const expiresInInMs = now + (authorization?.expires_in || 10000) * 1000

          setAuthValue({ ...authorization, expires_in: expiresInInMs })
          setUserValue(user)
          onSuccess()
        },
        onError,
      },
    )
  }, [])

  const logout = useCallback(() => {
    setAuthValue(null)
    setUserValue(null)
    clearFormStorageData()
  }, [setAuthValue, setUserValue])

  return {
    isAuthenticated: !!authValue,
    user: {
      name: userValue?.name ?? '',
      ...userValue,
    },
    login,
    logout,
    isExpired,
    getToken,
    loginMutation,
  }
}

export { useAuthentication, type IAuthentication }
