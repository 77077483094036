import styled from 'styled-components'

export const SubmittedContainer = styled.div`
  flex: 1;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 4.5rem 1.5rem 7rem;
  background: ${({ theme }) => theme.palette.windowBackground};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  align-items: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 4.5rem 1rem;
      border-radius: ${theme.shape.borderRadiusSmall};
    }
  `}
`

export const SubmittedHeading = styled.div`
  margin: 1.5rem 0;
`

export const TransactionNumberContainer = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Selection = styled.div`
  background: ${({ theme }) => theme.palette.background};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 0.5rem 1.5rem;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      width: 100%;
      
      & > div {
        flex: 1;
        flex-direction: column;
      }
    }
  `}
`
