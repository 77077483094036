import styled from 'styled-components'

export const FormButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 1rem;
    }
  `}
`
