import { Container, Heading1, Subheader } from '@mvr/ui'
import { useEffect } from 'react'

import { PageProps } from 'common'

import { Profile } from 'components'

import { Main } from './ProfilePage.styles'

export const ProfilePage = ({ title }: PageProps): JSX.Element => {
  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <Subheader>
        <Heading1>Profile</Heading1>
      </Subheader>

      <Main>
        <Container size='sm'>
          <Profile />
        </Container>
      </Main>
    </>
  )
}
