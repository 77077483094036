import styled from 'styled-components'

export const ForgotPinCodeLink = styled.div`
  text-align: center;
  margin: 1.5rem 0 1rem;

  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.statusBlue};

    &:hover {
      text-decoration: none;
    }
  }
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
`
