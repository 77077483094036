import { Container, Heading1, Subheader, useMedia } from '@mvr/ui'
import React, { useEffect } from 'react'

import { useClearFormStorageData } from 'hooks'

import { PageProps } from 'common'

import { Archive, CreateNewFormButtons } from 'components'

import { SubheaderContent } from './HomePage.styles'

export const HomePage = ({ title }: PageProps): JSX.Element => {
  const { clearFormStorageData } = useClearFormStorageData()
  const { isTablet } = useMedia()

  useEffect(() => {
    document.title = title
    clearFormStorageData()
  }, [title])

  return (
    <>
      <Subheader>
        <SubheaderContent>
          <Heading1>{isTablet ? 'Verifications' : 'Motor Vehicle Verifications'}</Heading1>

          <CreateNewFormButtons />
        </SubheaderContent>
      </Subheader>

      <Container size='lg'>
        <Archive />
      </Container>
    </>
  )
}
