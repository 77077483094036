import { FormikInput, FormikSelect, Heading2 } from '@mvr/ui'
import { Dictionary, dictionaryToOptions } from 'utils/data'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

type InfoProps = {
  dictionaries: {
    fuel_type: Dictionary
  }
}

export const EngineDetails = ({ dictionaries }: InfoProps) => {
  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Engine Details</Heading2>
      </CardHeading>

      <InputRow>
        <FormikInput
          name='engine_number'
          placeholder='Engine Number'
          maxLength={30}
          hint='A unique number allocated to the vehicle’s engine when the engine is manufactured'
          autoComplete='off'
          required
        />

        <FormikSelect
          name='fuel_type'
          placeholder='Fuel Type'
          options={dictionaryToOptions(dictionaries.fuel_type)}
          searchable
          required
        />
      </InputRow>
    </FormContainer>
  )
}
