import { isAxiosError } from 'axios'
import { StatusCodes } from 'constants/statusCodes'
import { FormikErrors } from 'formik'
import { useState } from 'react'

import { paths } from 'api'
import { useFetch } from 'hooks'

export interface UserPINProps {
  current_pin_code: string
  new_pin_code: string
  new_pin_code_confirmation: string
}

type ErrorsType = (errors: FormikErrors<UserPINProps>) => void

export const useChangePIN = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { usePostMutation } = useFetch()
  const changePINMutation = usePostMutation([paths.changePin()])
  const formValues = {
    current_pin_code: '',
    new_pin_code: '',
    new_pin_code_confirmation: '',
  }

  const handleSubmit = (formData: UserPINProps, setErrors: ErrorsType, resetForm: () => void) => {
    changePINMutation.mutate(
      { path: paths.changePin(), body: formData },
      {
        onSuccess: () => {
          setIsModalOpen(true)
          resetForm()
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response?.status === StatusCodes.UNPROCESSABLE_CONTENT) {
            return setErrors({ current_pin_code: 'Current PIN is incorrect. Please check and try again.' })
          }
        },
      },
    )
  }

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  return {
    isModalOpen,
    onModalClose,
    formValues,
    handleSubmit,
  }
}
