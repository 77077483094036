import { useSearchParams } from 'react-router-dom'

import { paths } from 'api'
import { useFetch } from 'hooks'
import { useAuth } from 'services'

import { VerificationCenterProps, VerificationTypes } from '../common'

export const useVerificationCenters = () => {
  const [params] = useSearchParams()
  const formKindQuery: string = params.get('kind')
  const user = useAuth()
  const { useGetQuery } = useFetch()
  const result = useGetQuery<{ data: Array<VerificationCenterProps> }>([user, paths.centers()], paths.centers())
  let centers = result?.data?.data?.data ?? []
  let isFirstRegistration = false
  let isPostRegistration = false

  centers.forEach(({ services }) => {
    services.forEach(({ value }) => {
      if (value === VerificationTypes.FIRST) {
        isFirstRegistration = true
      }

      if (value === VerificationTypes.POST) {
        isPostRegistration = true
      }
    })
  })

  const isFirstAndPostRegistration = isFirstRegistration && isPostRegistration

  isFirstRegistration = formKindQuery
    ? formKindQuery !== 'post' && (isFirstRegistration || isFirstAndPostRegistration)
    : isFirstRegistration || isFirstAndPostRegistration

  isPostRegistration = formKindQuery ? formKindQuery === 'post' && isPostRegistration : isPostRegistration

  centers = centers.filter((c) =>
    c.services.some((s) => s.value === (isFirstRegistration ? VerificationTypes.FIRST : VerificationTypes.POST)),
  )

  // add others if necessary
  return {
    centers,
    isFirstRegistration,
    isPostRegistration,
    isFirstAndPostRegistration,
    isLoading: result.isLoading,
  }
}
