import styled from 'styled-components'

export const LogoWrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      margin-bottom: 2rem;
    }
  `}

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      max-width: 1.5rem;
    }
  `}
`

export const LoginContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background};
  padding: 3.75rem 1rem 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      padding-top: 2rem;
    }
  `}
`

export const LoginContent = styled.div`
  position: relative;
  max-width: 27.37rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.windowBackground};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  padding: 1.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      max-width: 21.35rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      border-radius: ${theme.shape.borderRadiusMedium};
      padding: 1.5rem 1rem;
    }
  `}
`
