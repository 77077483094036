import { Alert } from '@mvr/ui'
import { createContext, useContext, useEffect, useReducer } from 'react'

import { IAction, IAlert, IDefaultValue, IProvideAlertProps, IrenderAlertProps } from 'common'

const DEFAULT_VALUE: IDefaultValue = {
  alert: {
    type: 'error',
    text: '',
    visible: false,
  },
  showAlert: () => null,
  hideAlert: () => null,
  renderAlert: () => null,
}

const AlertContext = createContext(DEFAULT_VALUE)

const alertReducer = (state: IDefaultValue, action: IAction) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: action.payload,
      }

    case 'HIDE_ALERT':
      return DEFAULT_VALUE

    default:
      return state
  }
}

const useAlert = () => {
  const context = useContext(AlertContext)

  useEffect(
    () => () => {
      if (context.alert.visible) {
        context.hideAlert()
      }
    },
    [],
  )

  return context
}

const AlertProvider = ({ children }: IProvideAlertProps) => {
  const [state, dispatch] = useReducer(alertReducer, DEFAULT_VALUE)

  // Actions
  const showAlert = ({ type = 'success', text = '', visible = true }: IAlert) => {
    const payload = {
      type,
      text,
      visible,
    }
    dispatch({ type: 'SHOW_ALERT', payload })
  }

  const hideAlert = () => {
    dispatch({ type: 'HIDE_ALERT', payload: DEFAULT_VALUE.alert })
  }

  const renderAlert = ({ style = {}, ...rest }: IrenderAlertProps = {}) =>
    state.alert.visible ? <Alert style={style} severity={state.alert.type} text={state.alert.text} {...rest} /> : null

  return (
    <AlertContext.Provider
      value={{
        alert: state.alert,
        showAlert,
        hideAlert,
        renderAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export { AlertProvider, AlertContext, useAlert }
