import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

import { SIDES } from './sides'

export const VehiclePhotoValidationSchema = Yup.object().shape(
  SIDES.reduce(
    (acc, { title: fieldName, name, schema }) => ({
      ...acc,
      [name]:
        schema ||
        Yup.mixed().required(formatErrorMessage('required', { fieldName })).maxFileSize({ fieldName: 'File', size: 2 }),
    }),
    {},
  ),
)
