import filter from 'lodash/filter'

import { paths } from 'api'
import { useFetch } from 'hooks'

import { FormProps, FormsRawResponseProps, VerificationTypes } from 'common'

const types = {
  [VerificationTypes.FIRST]: 'First',
  [VerificationTypes.POST]: 'Post',
}

const useArchiveData = (searchValue?: string) => {
  const { useGetQuery } = useFetch()
  const firstFormListUrl = paths.forms({ isFirstRegistration: true })
  const postFormListUrl = paths.forms({ isFirstRegistration: false })
  const firstFormsQuery = useGetQuery<FormsRawResponseProps<FormProps[]>>([firstFormListUrl], firstFormListUrl)
  const postFormsQuery = useGetQuery<FormsRawResponseProps<FormProps[]>>([postFormListUrl], postFormListUrl)
  const { isLoading: firstFormsIsLoading, isError: firstFormsIsError } = firstFormsQuery
  const { isLoading: postFormsIsLoading, isError: postFormsIsError } = postFormsQuery

  const forms =
    firstFormsQuery.isSuccess && postFormsQuery.isSuccess
      ? filter([...firstFormsQuery.data.data.data, ...postFormsQuery.data.data.data], (item) => {
          if (!searchValue || !item.vin) {
            return true
          }

          return item.vin.toLowerCase().includes(searchValue?.toLowerCase() || '')
        })
      : []

  const total = forms.length
  const isLoading = firstFormsIsLoading || postFormsIsLoading
  const isError = firstFormsIsError || postFormsIsError
  const isNoResults = total === 0 && searchValue
  const isNotFound = total === 0 && !searchValue

  const formatData = () => {
    return forms.map((form: FormProps) => {
      return {
        VIN: form.vin,
        'Created at': form.created_at,
        'Form number': form.number,
        Type: types[form.service_type],
      }
    })
  }

  return {
    forms: formatData(),
    total,
    isLoading,
    isError,
    isNoResults,
    isNotFound,
  }
}

export default useArchiveData
