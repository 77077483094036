import { Button, FormikInput, Heading2, ICONS, Icon, Text, lightTheme } from '@mvr/ui'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { BackLink, ButtonWrapper, FormContent, FormHeader, TextWrapper } from './ResetPinCode.styles'
import { resetPinSchema } from './ResetPinCode.validation'
import useResetPinCode from './useResetPinCode'

export const ResetPinCode = (): JSX.Element => {
  const { initialValues, isLoading, onSubmit } = useResetPinCode()

  return (
    <>
      <BackLink>
        <Icon icon={ICONS.ANGLE_LEFT} size={lightTheme.size.icon.sm} />
        <Link to={'/login'}>Back to log in</Link>
      </BackLink>

      <FormHeader>
        <Heading2>Forgot your PIN code?</Heading2>

        <TextWrapper>
          <Text>No worries, we&apos;ll send you a new PIN code on email.</Text>
        </TextWrapper>
      </FormHeader>

      <FormContent>
        <Formik
          initialValues={initialValues}
          onSubmit={(formData, { setErrors }) => onSubmit(formData, setErrors)}
          validationSchema={resetPinSchema}
        >
          {({ errors }) => (
            <Form>
              <FormikInput name='email' type='text' placeholder='Enter your email' />

              <ButtonWrapper>
                <Button type='submit' appearance='primary' disabled={isLoading || !!errors.email} fullWidth>
                  Request new PIN code
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormContent>
    </>
  )
}
