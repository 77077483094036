import { useCallback, useState } from 'react'

const useLocalStorage = <T>(key: string, defaultValue: T | null = null) => {
  // TODO: add effect on key change

  const [localStorageValue, setLocalStorageValue] = useState<T | null>(() => {
    try {
      const value = localStorage.getItem(key)

      if (value) {
        return JSON.parse(value)
      } else {
        return defaultValue
      }
    } catch (error) {
      return defaultValue
    }
  })

  const setLocalStorageStateValue = useCallback(
    (value: T | null) => {
      if (value) {
        localStorage.setItem(key, JSON.stringify(value))
      } else {
        localStorage.removeItem(key)
      }

      setLocalStorageValue(value)
    },
    [key, setLocalStorageValue],
  )

  return { localStorageValue, setLocalStorageStateValue }
}

export { useLocalStorage }
