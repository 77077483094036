import { Header, ICONS } from '@mvr/ui'
import { ROUTES } from 'constants/routes'
import { ReactNode } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { useAuth } from 'services'

interface MainPageProps {
  children: ReactNode
}

export const MainPage = ({ children }: MainPageProps): JSX.Element => {
  const { isExpired, logout } = useAuth()
  const navigate = useNavigate()

  if (isExpired()) {
    logout()
    return <Navigate replace to={ROUTES.login} />
  }

  return (
    <>
      <Header
        navigation={[
          {
            caption: 'Profile',
            type: 'link',
            onClick: () => navigate(ROUTES.profile),
          },
          {
            caption: 'Logout',
            type: 'button',
            icon: ICONS.BOX_ARROW_IN_RIGHT,
            onClick: () => logout(),
          },
        ]}
        onLogoClick={() => navigate(ROUTES.home)}
        logoMVV
      />

      {children}
    </>
  )
}
