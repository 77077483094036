import { Container, Heading1, Subheader, useMedia } from '@mvr/ui'
import { useEffect } from 'react'

import { Form } from 'components'

interface FormPageProps {
  title: string
}

export const FormPage = ({ title }: FormPageProps): JSX.Element => {
  const { isTablet } = useMedia()
  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <Subheader>
        <Heading1>{isTablet ? 'Verification' : 'Motor Vehicle Verification Form'}</Heading1>
      </Subheader>

      <Container size='md'>
        <Form />
      </Container>
    </>
  )
}
