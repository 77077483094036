import styled from 'styled-components'

export const SubheaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 1rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      flex-direction: column;
    }
  `}

  & button {
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.sm}) {
        ${theme.typography.mediumSemiBold};
        border-radius: ${theme.shape.borderRadiusSmall};
        height: 2.5rem;
        padding: 0 1rem;
      }
  `}
  }
`
