import { formatErrorMessage } from 'locale'
import { cachePreviousValidation, isEngineNumberUniqueQuery } from 'utils/validation'
import * as Yup from 'yup'

export const EngineDetailsEngineNumberExistsValidationSchema = Yup.object().shape({
  engine_number: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Engine Number' }))
    .max(
      30,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Engine Number',
        value: 30,
      }),
    )
    .charsNumbers({ fieldName: 'Engine Number' })

    .test(
      'asyncTest',
      formatErrorMessage('uniqueness', { fieldName: 'Engine Number' }),
      cachePreviousValidation('', isEngineNumberUniqueQuery),
    ),
})

export const EngineDetailsValidationSchema = Yup.object().shape({
  engine_number: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Engine Number' }))
    .max(
      30,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Engine Number',
        value: 30,
      }),
    )
    .charsNumbers({ fieldName: 'Engine Number' }),

  fuel_type: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Fuel Type' })),
})
