import { GlobalStyle, lightTheme } from '@mvr/ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ROUTES } from 'constants/routes'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'utils/validation'

import { AlertProvider, AuthProvider } from 'services'

import { FormPage, HomePage, LoginPage, ProfilePage } from 'pages'

import { ErrorBoundary, LoginByCode, ResetPinCode, ResetPinCodeSuccess } from 'components'
import { ThemeProvider } from 'styled-components'

import { AuthRoute, ProtectedRoute } from './route'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={lightTheme}>
        <ErrorBoundary>
          <AuthProvider>
            <AlertProvider>
              <BrowserRouter>
                <Routes>
                  <Route path={ROUTES.login} element={<AuthRoute />}>
                    <Route path='' element={<LoginPage />}>
                      <Route path='' element={<LoginByCode />} />
                      <Route path={ROUTES.resetPin} element={<ResetPinCode />} />
                      <Route path={ROUTES.resetPinSuccess} element={<ResetPinCodeSuccess />} />
                    </Route>
                  </Route>
                  <Route element={<ProtectedRoute />}>
                    <Route path={ROUTES.home} element={<HomePage title='MVVF - Home' />} />
                    <Route path={ROUTES.form} element={<FormPage title='MVVF - Form' />} />
                    <Route path={ROUTES.profile} element={<ProfilePage title='MVVF - Profile' />} />
                  </Route>
                  <Route path='*' element={<ProtectedRoute />} />
                </Routes>
              </BrowserRouter>
            </AlertProvider>
          </AuthProvider>
        </ErrorBoundary>
        <GlobalStyle />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
