import { useFormikContext } from 'formik'
import { isEqual } from 'lodash'
import { useEffect, useRef } from 'react'

interface FormikPersistProps {
  name: string
  maxStorageSize?: number // in bytes
}

const FormikPersist = ({ name, maxStorageSize = 5 * 1024 * 1024 }: FormikPersistProps) => {
  const { values, setValues } = useFormikContext()
  const prefValuesRef = useRef(values)

  const base64ToFile = async (base64String, filename, type) => {
    const response = await fetch(base64String)
    const blob = await response.blob()
    return new File([blob], filename, { type })
  }

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  const valuesHaveChanged = async (values, parsedForm) => {
    for (const key of Object.keys(values)) {
      const currentValue = values[key]
      const parsedValue = parsedForm[key]

      if (Array.isArray(currentValue) && Array.isArray(parsedValue)) {
        if (currentValue.length !== parsedValue.length) {
          return true
        }

        for (let i = 0; i < currentValue.length; i++) {
          const currentFile = currentValue[i]
          const parsedFile = parsedValue[i]

          if (currentFile instanceof File && parsedFile instanceof File) {
            const nameChanged = currentFile.name !== parsedFile.name
            const sizeChanged = currentFile.size !== parsedFile.size

            if (nameChanged || sizeChanged) {
              return true
            }

            if (!(await isEqual(await fileToBase64(currentFile), await fileToBase64(parsedFile)))) {
              return true
            }
          } else if (!isEqual(currentFile, parsedFile)) {
            return true
          }
        }
      } else if (!isEqual(currentValue, parsedValue)) {
        return true
      }
    }
    return false
  }

  const convertValuesToBase64 = async (values) => {
    const entries = await Promise.all(
      Object.entries(values).map(async ([key, value]) => {
        if (Array.isArray(value)) {
          const filesWithBase64 = await Promise.all(
            value.map(async (file) => {
              if (file instanceof File) {
                const base64String = await fileToBase64(file)
                const filename = file.name

                return { uri: base64String, filename }
              }
              return file
            }),
          )
          return [key, filesWithBase64]
        }
        return [key, value]
      }),
    )
    return Object.fromEntries(entries)
  }

  const calculateSize = (obj) => {
    const str = JSON.stringify(obj)
    return new Blob([str]).size
  }

  const onSave = async (values) => {
    const savedForm = window.localStorage.getItem(name)

    const valuesWithBase64Files = await convertValuesToBase64(values)
    const valuesWithBase64FilesObject = valuesWithBase64Files

    if (savedForm) {
      const parsedForm = JSON.parse(savedForm)
      const hasChanged = await valuesHaveChanged(values, parsedForm)

      if (hasChanged) {
        const size = calculateSize(valuesWithBase64FilesObject)

        if (size > maxStorageSize) {
          // Remove files to fit within the storage limit
          const valuesWithoutFiles = Object.entries(values).map(([key, value]) => {
            if (Array.isArray(value)) {
              return [key, value.filter((file) => !(file instanceof File))]
            }
            return [key, value]
          })
          window.localStorage.setItem(name, JSON.stringify(Object.fromEntries(valuesWithoutFiles)))
        } else {
          window.localStorage.setItem(name, JSON.stringify(valuesWithBase64FilesObject))
        }
      }
    } else {
      window.localStorage.setItem(name, JSON.stringify(valuesWithBase64FilesObject))
    }
  }

  useEffect(() => {
    const savedForm = window.localStorage.getItem(name)

    if (savedForm) {
      const parsedForm = JSON.parse(savedForm)
      const filesPromises = Object.keys(parsedForm).map(async (key) => {
        const value = parsedForm[key]

        if (Array.isArray(value)) {
          const filesWithBase64 = await Promise.all(
            value.map(async (base64Object) => {
              if (typeof base64Object === 'object' && 'uri' in base64Object) {
                const filename = base64Object.filename || 'filename'
                return await base64ToFile(base64Object.uri, filename, 'image/jpeg')
              }
              return base64Object
            }),
          )

          parsedForm[key] = filesWithBase64
        }
      })

      Promise.all(filesPromises).then(() => {
        prefValuesRef.current = parsedForm

        setValues(parsedForm)
      })
    }
  }, [name, setValues])

  useEffect(() => {
    if (!isEqual(prefValuesRef.current, values)) {
      onSave(values)
    }
  }, [values])

  useEffect(() => {
    prefValuesRef.current = values
  }, [values])

  return null
}

export default FormikPersist
