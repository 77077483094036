import {
  MutationKey,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios'
import { StatusCodes } from 'constants/statusCodes'

import { axiosClient } from '../api'
import { useAuth } from '../services'

import { IMutationBody } from 'common'

const useFetch = () => {
  const queryClient = useQueryClient()
  const { logout } = useAuth()

  const handleError = (error) => {
    if (isAxiosError(error) && error.response?.status === StatusCodes.UNAUTHORIZED) {
      logout()
    }
  }

  const useGetQuery = <T>(
    key: QueryKey,
    url: string,
    options?: UseQueryOptions<AxiosResponse<T>, AxiosError, AxiosResponse<T>>,
    callback?: (responce: AxiosResponse<T>) => void,
  ) =>
    useQuery<AxiosResponse<T>, AxiosError, AxiosResponse<T>>(key, {
      queryFn: (): Promise<AxiosResponse<T>> =>
        axiosClient.get<T>(url).then((data) => {
          callback?.(data)
          return data
        }),
      onError: (error) => handleError(error),
      ...options,
    })

  const usePostMutation = <T>(
    key: MutationKey,
    options?: UseMutationOptions<AxiosResponse<T>, AxiosError, IMutationBody>,
    config?: AxiosRequestConfig<T>,
  ) =>
    useMutation<AxiosResponse<T>, AxiosError, IMutationBody>(key, {
      mutationFn: ({ path, body }: IMutationBody): Promise<AxiosResponse<T>> => axiosClient.post<T>(path, body, config),

      onSettled: () => queryClient.invalidateQueries(key),
      onError: (error) => handleError(error),
      ...options,
    })

  return { useGetQuery, usePostMutation }
}

export { useFetch }
