import styled from 'styled-components'

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const FileUploadFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const SideImage = styled.img`
  margin-top: 0.5rem;
`
