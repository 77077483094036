import styled from 'styled-components'

export const ProfileContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 1.5rem;
  margin: 0 auto;

  ${({ theme }) => `
    background: ${theme.palette.windowBackground};
    border-radius: ${theme.shape.borderRadius};
    
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 1rem;
      border-radius: ${theme.shape.borderRadiusSmall};
    }
  `}
`

export const Heading = styled.div`
  margin-bottom: 2rem;
`

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const FormButton = styled.div`
  margin-top: 0.5rem;
`
