import { useMedia } from '@mvr/ui'

import { EntryInfoFirst } from './EntryInfo/First/First'
import { EntryInfoFirstValidationSchema } from './EntryInfo/First/First.validation'
import { EntryInfoPost } from './EntryInfo/Post/Post'
import { EntryInfoPostValidationSchema } from './EntryInfo/Post/Post.validation'
import { LastStepFooter } from './LastStepFooter/LastStepFooter'
import { LastStepFooterValidationSchema } from './LastStepFooter/LastStepFooter.validation'
import {
  FirstVehicleDetailsValidationSchema,
  PostVehicleDetailsValidationSchema,
  VehicleDetails,
} from './VehicleDetails'
import { VehiclePhoto } from './VehiclePhoto/VehiclePhoto'
import { VehiclePhotoValidationSchema } from './VehiclePhoto/VehiclePhoto.validation'

const entryInfoTypes = {
  first: {
    component: EntryInfoFirst,
    validationSchema: EntryInfoFirstValidationSchema,
  },

  post: {
    component: EntryInfoPost,
    validationSchema: EntryInfoPostValidationSchema,
  },
}

const vehicleInfoTypes = {
  first: {
    validationSchema: FirstVehicleDetailsValidationSchema,
  },

  post: {
    validationSchema: PostVehicleDetailsValidationSchema,
  },
}

type GetStepsProps = {
  isFirstRegistration: boolean
}

export const getSteps = ({ isFirstRegistration }: GetStepsProps) => {
  const entryInfoType = entryInfoTypes[isFirstRegistration ? 'first' : 'post']
  const vehicleInfoType = vehicleInfoTypes[isFirstRegistration ? 'first' : 'post']
  const { isTablet } = useMedia()

  return [
    {
      title: 'Entry Info',
      ...entryInfoType,
    },

    {
      title: isTablet ? 'Details' : 'Vehicle Details',
      component: VehicleDetails,
      ...vehicleInfoType,
    },

    {
      title: isTablet ? 'Photo' : 'Vehicle Photo',
      component: VehiclePhoto,
      validationSchema: VehiclePhotoValidationSchema,
    },

    {
      title: 'Summary',
      component: (props: any) => (
        <>
          <entryInfoType.component {...props} />
          <VehicleDetails {...props} />
          <VehiclePhoto {...props} />
          <LastStepFooter {...props} />
        </>
      ),
      validationSchema: LastStepFooterValidationSchema,
    },
  ]
}
