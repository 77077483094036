import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const EntryInfoFirstValidationSchema = Yup.object().shape({
  entry_number: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Entry Number' }))
    .charsNumbersSpaces({ fieldName: 'Entry Number' })
    .max(
      50,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Entry Number',
        value: 50,
      }),
    ),

  verification_center_uuid: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Verification Centre' })),

  customs_agent_uuid: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Customs Agent' })),
})
