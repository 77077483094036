import { StyledPaper } from '@mvr/ui'

import styled from 'styled-components'

export const FormWrapper = styled.div`
  margin-top: 1.5rem;
`

export const StepperWrapper = styled.div`
  padding: 1.5rem 0.5rem 0;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      margin: auto;
      max-width: 80%;
    }
  `}
`

export const Paper = styled(StyledPaper)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 1.25rem 0 7.5rem;
  position: relative;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 0.5rem;
    }
  `}
`

export const CardHeading = styled.div`
  margin-bottom: 1.5rem;
`

export const CardHint = styled.div`
  ${({ theme }) => theme.typography.bodyRegular};
  color: ${({ theme }) => theme.palette.contrastMedium};
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 50vw;
  max-width: 650px;
  height: 25vh;
  left: 25vw;
  right: 25vw;
  margin: auto;
  top: 25vh;
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  z-index: 1;
`

export const SubmitModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  padding: 4rem 0 6rem;
`

// @TODO: to fix breakpoint in mvr ui
export const InputRow = styled.div<{ $columns?: number }>`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns ?? 2}, 1fr);
  gap: 1.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  `}
`
