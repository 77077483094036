import { FormsProps, IGetApi } from 'common'

class GetApi implements IGetApi {
  forms = ({ isFirstRegistration }: FormsProps) => {
    return `forms/${isFirstRegistration ? 'first' : 'post'}` as const
  }

  formsEngineNumber = () => {
    return '/forms/first/engine-number' as const
  }

  formsVin = () => {
    return '/forms/first/vin' as const
  }

  formsFields = () => {
    return '/forms/fields' as const
  }

  formsDictionaries = () => {
    return 'forms/lists' as const
  }

  makes = () => {
    return 'makes' as const
  }

  centers = () => {
    return 'centers' as const
  }

  customAgents = () => {
    return 'customs-agents' as const
  }

  sendEmail = (token?: string | number | undefined) => {
    return `forms/${token}/send-email` as const
  }

  login = () => {
    return 'auth/login' as const
  }

  resetPin = () => {
    return 'auth/reset-pin' as const
  }

  userInfo = () => {
    return 'users/info' as const
  }

  changePin = () => {
    return 'users/pin-code' as const
  }
}

export const paths = new GetApi()
