import styled from 'styled-components'

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      flex-direction: column;
      gap: 1.5rem;
      
      & button {
        height: 3.25rem;
        ${theme.typography.regularSemiBold}
        
        &:first-child {
          order: 1;
        }
      }
    }
  `}
`
