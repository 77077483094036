import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const TyreDetailsValidationSchema = Yup.object().shape({
  tyres_count: Yup.number()
    .required(formatErrorMessage('required', { fieldName: 'No. of Tyres' }))
    .rangeNumber({
      min: 1,
      max: 99,
      fieldName: 'No. of Tyres',
    }),

  spare_wheel: Yup.number()
    .required(formatErrorMessage('required', { fieldName: 'Spare Wheel' }))
    .rangeNumber({
      min: 0,
      max: 99,
      fieldName: 'Spare Wheel',
    }),

  front_tyres_size: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Front Tyres size' }))
    .tyreSizeFormat({ fieldName: 'Front Tyres' }),

  rear_tyres_size: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Rear Tyres size' }))
    .tyreSizeFormat({ fieldName: 'Rear Tyres' }),
})
