import { Button, FormikInput } from '@mvr/ui'
import { ROUTES } from 'constants/routes'
import { Form, Formik } from 'formik'
import { Link } from 'react-router-dom'

import { loginSchema } from './LoginByCode.validation'
import { ButtonWrapper, ForgotPinCodeLink, FormContent } from './styles'
import useLoginByCode from './useLoginByCode'

export const LoginByCode = (): JSX.Element => {
  const { initialValues, isLoading, onSubmit, handleChange } = useLoginByCode()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(formData, { setErrors }) => onSubmit(formData, setErrors)}
    >
      <Form autoComplete='false'>
        <FormContent>
          <FormikInput
            disabled={isLoading}
            name='code'
            type='tel'
            placeholder='Employee code'
            autoComplete='false'
            onChange={handleChange}
          />
          <FormikInput
            disabled={isLoading}
            name='pin_code'
            type='password'
            placeholder='PIN code'
            autoComplete='false'
            onChange={handleChange}
          />
        </FormContent>

        <ButtonWrapper>
          <Button type='submit' disabled={isLoading} fullWidth>
            Log in
          </Button>
        </ButtonWrapper>

        <ForgotPinCodeLink>
          <Link to={ROUTES.resetPin}>Forgot PIN code?</Link>
        </ForgotPinCodeLink>
      </Form>
    </Formik>
  )
}
