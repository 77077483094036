import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const ChangePINValidationSchema = Yup.object().shape({
  current_pin_code: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Current PIN' }))
    .pinFormat({ fieldName: 'PIN' }),

  new_pin_code: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'New PIN' }))
    .pinFormat({ fieldName: 'PIN' }),

  new_pin_code_confirmation: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Confirm PIN' }))
    .pinFormat({ fieldName: 'PIN' })
    .when('new_pin_code', ([newPinCode] = [], schema) => {
      return schema.test({
        test: (new_pin_code_confirmation) => new_pin_code_confirmation === newPinCode,
        message: formatErrorMessage('notEqual', { fieldName: 'PIN confirmation' }),
      })
    }),
})
