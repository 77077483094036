import { Asterix, Heading2, RedesignedFormikFileUpload, RedesignedPreviewFile } from '@mvr/ui'

import styled from 'styled-components'

import { CardHeading, CardHint, FormContainer } from '../../Form.styles'
import { FieldList, FileUploadFieldContainer, SideImage } from './VehiclePhoto.styles'
import { SIDES } from './sides'

export const StyledPreviewFile = styled(({ className, ...props }) => (
  <div className={className}>
    <RedesignedPreviewFile {...props} />
  </div>
))``

export const VehiclePhoto = () => (
  <FormContainer>
    <CardHeading>
      <Heading2>Photos</Heading2>
      <CardHint>Supported formats: jpg or png.</CardHint>
    </CardHeading>

    <FieldList>
      {SIDES.map(({ title, multiple, name, image, required }, i) => (
        <FileUploadFieldContainer key={name}>
          <Heading2>
            {i + 1}.{' '}
            {required ? (
              <>
                {title}
                <Asterix />
              </>
            ) : (
              title
            )}
          </Heading2>

          {image ? (
            <div>
              <SideImage src={image} alt={title} />
            </div>
          ) : null}

          <RedesignedFormikFileUpload
            name={name}
            type={'photos'}
            multiple={multiple}
            components={{
              RedesignedPreviewFileContainer: StyledPreviewFile as any,
            }}
            capture='environment'
          />
        </FileUploadFieldContainer>
      ))}
    </FieldList>
  </FormContainer>
)
