import { paths } from 'api'
import { useFetch } from 'hooks'

// type  = 'MVR_CENTER' | 'CFS_BOND'

type Importer = any

export const useCustomAgents = () => {
  const { useGetQuery } = useFetch()
  const query = useGetQuery<{ data: Importer[] }>([paths.customAgents()], paths.customAgents())
  const customAgents = query?.data?.data?.data ?? []

  return {
    ...query,
    customAgents,
  }
  //
  // // if any from the list has one type - all has one type
  // // if this type is CFS - it is first registration
  // // else - it is not
  // const isFirstRegistration = !customAgents.length || centers.some(({ type }: { type: CenterTypes }) => type === cfs)
  //
  // // add others if necessary
  // return {
  //   centers,
  //   isFirstRegistration,
  //   isLoading: result.isLoading,
  // }
}
