import * as Yup from 'yup'

export const SIDES = [
  {
    name: 'photo_vin',
    title: 'VIN/Chassis No.',
    multiple: false,
    required: true,
  },

  {
    name: 'photo_engine_number',
    title: 'Engine Number',
    multiple: false,
    schema: Yup.mixed().optional().maxFileSize({ fieldName: 'File', size: 2 }),
    required: false,
  },

  {
    name: 'photo_front_side',
    title: 'Front Side',
    image: '/images/front_side.png',
    multiple: false,
    required: true,
  },

  {
    name: 'photo_left_side',
    title: 'Left Side',
    image: '/images/left_side.png',
    multiple: false,
    required: true,
  },

  {
    name: 'photo_rear_side',
    title: 'Rear Side',
    image: '/images/rear_side.png',
    multiple: false,
    required: true,
  },

  {
    name: 'photo_right_side',
    title: 'Right Side',
    image: '/images/right_side.png',
    multiple: false,
    required: true,
  },
]
