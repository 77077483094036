import { Heading2, ICONS, Icon, Text, lightTheme } from '@mvr/ui'
import React, { useEffect, useRef } from 'react'
import { copyToClipboardFromNode } from 'utils/copyToClipboardFromNode'

import { useClearFormStorageData } from 'hooks'

import { CreateNewFormButtons } from '../index'
import {
  ButtonsWrapper,
  Selection,
  SubmittedContainer,
  SubmittedHeading,
  TransactionNumberContainer,
} from './Submitted.styles'

type SubmittedProps = {
  onClose: () => void
  formNumber?: string | number
}

export const Submitted = ({ onClose, formNumber }: SubmittedProps) => {
  const { clearFormStorageData } = useClearFormStorageData()
  const ref = useRef()

  useEffect(() => {
    clearFormStorageData()
  }, [])

  const copy = () => {
    copyToClipboardFromNode(ref?.current)
  }

  return (
    <SubmittedContainer>
      <Icon icon={ICONS.CHECK2_CIRCLE} color={lightTheme.palette.statusGreen} size={lightTheme.size.icon.xxl} />

      <SubmittedHeading>
        <Heading2>Successfully submitted!</Heading2>
      </SubmittedHeading>

      {formNumber ? (
        <TransactionNumberContainer>
          <Text>Form number</Text>
          <Selection>
            <span ref={ref} onClick={copy}>
              <Text>{formNumber}</Text>
            </span>
            <Icon icon={ICONS.BACK} size={lightTheme.size.icon.sm} />
          </Selection>
        </TransactionNumberContainer>
      ) : null}

      <ButtonsWrapper>
        <CreateNewFormButtons onClick={onClose} />
      </ButtonsWrapper>
    </SubmittedContainer>
  )
}
