import { Button, Heading2, Text } from '@mvr/ui'
import { ROUTES } from 'constants/routes'
import { useNavigate } from 'react-router-dom'

import { NewPasswordWrapper, TextWrapper } from './ResetPinCodeSuccess.styles'

export const ResetPinCodeSuccess = (): JSX.Element => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(ROUTES.login)
  }

  return (
    <NewPasswordWrapper>
      <Heading2>Great!</Heading2>

      <TextWrapper>
        <Text>Check your email for a new PIN code.</Text>
      </TextWrapper>

      <Button appearance='primary' onClick={goBack}>
        Back to log in
      </Button>
    </NewPasswordWrapper>
  )
}
