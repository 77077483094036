import styled from 'styled-components'

export const BackLink = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;

  ${({ theme }) => `
    ${theme.typography.smallBold}
  `}
`

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.palette.contrastMedium};
`
export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
`
