import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from 'services'

export const AuthRoute = (): JSX.Element => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate replace to='/' />
  }

  return <Outlet />
}
