import { createIntl, createIntlCache } from '@formatjs/intl'

import en from './en'
import { flatObject } from './utils'

const defaultLocale = 'en'

const cache = createIntlCache()

const locales: any = {
  en: flatObject(en),
}

const intl = createIntl(
  {
    locale: defaultLocale,
    messages: locales[defaultLocale],
  },
  cache,
)

export const formatMessage = intl.formatMessage

export const formatErrorMessage = (errorId: string, ...args: any) => formatMessage({ id: `Error.${errorId}` }, ...args)
