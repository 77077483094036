import { FormikInput, Heading2, useMedia } from '@mvr/ui'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

export const TyreDetails = () => {
  const { isMobile } = useMedia()
  const placeholderEnd = isMobile ? '' : '(e.g. 255/45R17)'

  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Tyre Details</Heading2>
      </CardHeading>

      <InputRow>
        <FormikInput type='number' name='tyres_count' placeholder='No. of Tyres' autoComplete='off' required />

        <FormikInput type='number' name='spare_wheel' placeholder='Spare Wheel' autoComplete='off' required />
      </InputRow>

      <InputRow>
        <FormikInput
          name='front_tyres_size'
          placeholder={`Front Tyres size  ${placeholderEnd}`}
          hint='The size of the front tyres in format: "Width", "Aspect Ratio" and "Diameter" (for example, 225/45R17)'
          autoComplete='off'
          required
        />

        <FormikInput
          name='rear_tyres_size'
          placeholder={`Rear Tyres size  ${placeholderEnd}`}
          hint='The size of the rear tyres in format: "Width", "Aspect Ratio" and "Diameter" (for example, 225/45 R17)'
          autoComplete='off'
          required
        />
      </InputRow>
    </FormContainer>
  )
}
