import { Button } from '@mvr/ui'

import { ActionsContainer } from './Actions.styles'
import { useActions } from './useActions'

interface ActionsProps {
  isFirstStep: boolean
  step: number
  isLastStep: boolean
  onCancel: () => void
}

const Actions = ({ onCancel, isFirstStep, step, isLastStep }: ActionsProps) => {
  const { isSubmitting, nextClickHandle } = useActions(step)

  return (
    <ActionsContainer>
      <Button type='button' appearance='secondary' onClick={onCancel}>
        {isFirstStep ? 'Cancel' : 'Back'}
      </Button>

      <Button type='submit' disabled={isSubmitting} onClick={nextClickHandle}>
        {isLastStep ? 'Submit' : 'Next'}
      </Button>
    </ActionsContainer>
  )
}

export default Actions
