import { Navigate, Outlet } from 'react-router-dom'

import { useAuth } from 'services'

import { MainPage } from 'pages'

export const ProtectedRoute = (): JSX.Element => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return <Navigate replace to='/login' />
  }

  return (
    <MainPage>
      <Outlet />
    </MainPage>
  )
}
