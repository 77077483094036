import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const EntryInfoPostValidationSchema = Yup.object().shape({
  verification_center_uuid: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Verification Centre' })),

  registration_number: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Registration Number' }))
    .min(3, formatErrorMessage('minCharacters', { fieldName: 'Registration Number', value: 3 }))
    .max(
      11,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Registration Number',
        value: 11,
      }),
    )
    .charsNumbersSpaces({ fieldName: 'Registration Number' }),

  owner: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Owner' }))
    .min(2, formatErrorMessage('minCharacters', { fieldName: 'Owner', value: 2 }))
    .max(
      160,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Owner',
        value: 160,
      }),
    )
    .name({ fieldName: 'Owner' }),

  owners_phone: Yup.string()
    .trim()
    /* eslint-disable */
    .required(formatErrorMessage('required', { fieldName: "Owner's Phone" }))
    .phoneNumber({ fieldName: "Owner's Phone" }),
  /* eslint-enable */
})
