import { FormikCheckbox, FormikTextarea, Heading2 } from '@mvr/ui'

import { useAuth } from 'services'

import { CardHeading } from '../../Form.styles'
import { SignContainer, StyledFormContainer, TextareaWrapper } from './LastStepFooter.styles'

export const LastStepFooter = () => {
  const { user } = useAuth()

  return (
    <StyledFormContainer>
      <div>
        <CardHeading>
          <Heading2>Process Officer’s Comments</Heading2>
        </CardHeading>
        <TextareaWrapper>
          <FormikTextarea name='comment' placeholder='Comments text (Optional)' autoComplete='off' />
        </TextareaWrapper>
      </div>

      <SignContainer>
        <FormikCheckbox
          name='sign'
          label={`I, ${user.name}, confirm that the changes reflect the current state of the vehicle and have been completed correctly.`}
          required
        />
      </SignContainer>
    </StyledFormContainer>
  )
}
