export const copyToClipboardFromNode = (node: Element | null) => {
  if (!node || typeof window === 'undefined' || !document?.createRange || !window?.getSelection) {
    return
  }

  const range = document.createRange()

  if (!range?.selectNode) {
    return
  }

  range.selectNode(node)
  window.getSelection()?.removeAllRanges() // clear current selection
  window.getSelection()?.addRange(range) // to select text
  document.execCommand('copy')
  window.getSelection()?.removeAllRanges() // to deselect
}
