export const scrollTo = (to, yOffset = 0) => {
  const smoothScrollFeature = 'scrollBehavior' in document.documentElement.style

  if (typeof to === 'number') {
    window.scroll({
      top: to + yOffset,
      left: 0,
      behavior: smoothScrollFeature ? 'smooth' : 'auto',
    })
  } else {
    const toOffset = to.getBoundingClientRect().top + window.pageYOffset + yOffset

    if (window.pageYOffset !== toOffset) {
      if (!smoothScrollFeature) {
        let i = window.pageYOffset

        const scrollInterval = setInterval(() => {
          if (i < toOffset) {
            if (i > toOffset - 20) i += 1
            else if (i > toOffset - 40) i += 3
            else if (i > toOffset - 80) i += 8
            else if (i > toOffset - 160) i += 18
            else if (i > toOffset - 200) i += 24
            else if (i > toOffset - 300) i += 40
            else i += 60
            window.scroll(0, i)
            if (i >= toOffset) clearInterval(scrollInterval)
          } else {
            if (i < toOffset + 20) i -= 1
            else if (i < toOffset + 40) i -= 3
            else if (i < toOffset + 80) i -= 8
            else if (i < toOffset + 160) i -= 18
            else if (i < toOffset + 200) i -= 24
            else if (i < toOffset + 300) i -= 40
            else i -= 60
            window.scroll(0, i)
            if (i <= toOffset) clearInterval(scrollInterval)
          }
        }, 100)
      } else {
        window.scroll({
          top: toOffset + yOffset,
          left: 0,
          behavior: smoothScrollFeature ? 'smooth' : 'auto',
        })
      }
    }
  }
}
