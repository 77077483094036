import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      gap: 0.75rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 1rem;
    }
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 0.75rem;
    }
  `}
`

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      margin-top: 1.5rem;
    }
  `}

  & button {
    width: 12.87rem;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.md}) {
        width: 10rem;
      }
      
      @media (max-width: ${theme.breakPoints.sm}) {
        width: 7.5rem;
      }
    `}
  }
`
