import { FormikInput, FormikSelect, Heading2, Row } from '@mvr/ui'
import { useFormikContext } from 'formik'
import { dictionaryToOptions } from 'utils/data'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

type BodyDetailsProps = {
  dictionaries: any
}

export const BodyDetails = ({ dictionaries }: BodyDetailsProps) => {
  const {
    values: { vehicle_type },
    setFieldValue,
  } = useFormikContext<any>()

  const clearOnChange =
    <T,>(fieldNames: string[], oldValue: T) =>
    (newValue: T) => {
      fieldNames.forEach((fieldName) => {
        if (oldValue !== newValue) {
          setFieldValue(fieldName, undefined)
        }
      })
    }

  const checkIsEmptyArray = (dictionaries: any, vehicle_type: string): boolean => {
    if (dictionaries && vehicle_type) {
      return (
        Array.isArray(dictionaries.vehicle_category[vehicle_type]) &&
        dictionaries.vehicle_category[vehicle_type].length == 0
      )
    }
    return false
  }

  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Body Details</Heading2>
      </CardHeading>

      <InputRow>
        <FormikSelect
          name='body_color'
          placeholder='Colour'
          options={dictionaryToOptions(dictionaries.body_color)}
          searchable
          required
        />

        <FormikSelect
          name='vehicle_type'
          onChange={clearOnChange(['vehicle_category'], vehicle_type)}
          placeholder='Vehicle Type'
          options={dictionaryToOptions(dictionaries.vehicle_type)}
          searchable
          required
        />
      </InputRow>

      {vehicle_type &&
        dictionaries.vehicle_category[vehicle_type] &&
        !checkIsEmptyArray(dictionaries, vehicle_type) && (
          <Row>
            <FormikSelect
              name='vehicle_category'
              placeholder='Vehicle Category'
              disabled={!vehicle_type}
              options={
                vehicle_type && dictionaries.vehicle_category
                  ? dictionaryToOptions(dictionaries.vehicle_category[vehicle_type])
                  : []
              }
              searchable
              required
            />
          </Row>
        )}
      <Row>
        <FormikInput
          type='number'
          name='sitting_capacity'
          placeholder='Sitting Capacity'
          hint='The maximum seating capacity of the vehicle as determined by the manufacturer.'
          autoComplete='off'
          required
        />

        <FormikInput
          type='number'
          name='axles_count'
          placeholder='No. of Axles'
          hint='The number of driving axles'
          autoComplete='off'
          required
        />
      </Row>

      <Row>
        <FormikSelect
          name='hand_drive_type'
          placeholder='Hand Drive Type'
          options={dictionaryToOptions(dictionaries.hand_drive_type)}
          searchable
          required
        />

        <FormikSelect
          name='wheel_drive_type'
          placeholder='Wheel Drive Type'
          options={dictionaryToOptions(dictionaries.wheel_drive_type)}
          searchable
          required
        />
      </Row>
    </FormContainer>
  )
}
