export const formatPhone = (phone: string): string | undefined => {
  if (!phone) {
    return undefined
  }

  const newPhoneValue = phone.replace(/\+/g, '')
  const plus = newPhoneValue.startsWith('256') ? '+' : ''

  return `${plus}${newPhoneValue}`
}

const imageFileNames = [
  'front_side',
  'photo_vin',
  'photo_engine_number',
  'photo_left_side',
  'photo_front_side',
  'photo_rear_side',
  'photo_right_side',
]

export const valuesToData = (values: any) => {
  const photos = imageFileNames.reduce(
    (acc, key) => ({
      ...acc,
      [key]: values[key] && values[key][0] && values[key][0],
    }),
    {},
  )

  const data = {
    ...values,
    ...photos,

    owners_phone: formatPhone(values.owners_phone),

    vin: values.vin ? values.vin.toUpperCase() : undefined,

    is_new_brand: values.is_new_brand ? 1 : 0,
  }

  return data
}
