export const PAGINATION_ITEMS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },
  {
    label: '500',
    value: 500,
  },
]
