import { formatErrorMessage } from 'locale'
import { TestContext, addMethod, date, mixed, number, string } from 'yup'

import { axiosClient, paths } from '../api'

addMethod(string, 'name', function (values: any) {
  return this.matches(/^[a-zA-Z0-9|'|"| |.|\-|,]+$/, formatErrorMessage('name', values))
})

addMethod(string, 'text', function (values: any) {
  return this.matches(/^[\d|a-zA-Z|'|"| |.|\-|,]+$/, formatErrorMessage('text', values))
})

addMethod(string, 'model', function (values: any) {
  return this.matches(/^[\d|a-zA-Z| |\-|/|&]+$/, formatErrorMessage('model', values))
})

addMethod(string, 'charsNumbers', function (values: any) {
  return this.matches(/^[\d|a-zA-Z|]+$/, formatErrorMessage('charsNumbers', values))
})

addMethod(string, 'charsNumbersSpaces', function (values: any) {
  return this.matches(/^[\d|a-zA-Z| ]+$/, formatErrorMessage('charsNumbersSpaces', values))
})

addMethod(string, 'phoneNumber', function (values: any) {
  return this.matches(/^(0|\+256)\d{9}$/, formatErrorMessage('phoneNumber', values))
})

addMethod(string, 'tyreSizeFormat', function (values: any) {
  return this.matches(/^[\d|a-zA-Z| \/]+$/, formatErrorMessage('tyreSizeFormat', values))
})

addMethod(number, 'rangeNumber', function (values: any) {
  return this.transform((value) => (Number.isNaN(value) ? null : value))
    .min(values.min, formatErrorMessage('rangeNumber', values))
    .max(values.max, formatErrorMessage('rangeNumber', values))
})

addMethod(date, 'rangeYear', function (values: any) {
  return this.min(values.min, formatErrorMessage('minYear', values)).max(values.max, formatErrorMessage('dateInPast'))
})

addMethod(string, 'pinFormat', function (values: any) {
  return this.matches(/^\d{4}$/, formatErrorMessage('pinFormat', values))
})

addMethod(mixed, 'maxFileSize', function (values: any) {
  return this.test('maxFileSize', formatErrorMessage('maxFileSize', values), ([value]: any = []) => {
    if (!value || !(value instanceof File)) {
      return true // If no file is selected, it's valid
    }

    const maxSize = values.size * 1024 * 1024 // 2MB
    return value.size <= maxSize
  })
})

addMethod(string, 'vin', function (values: any) {
  const { fieldName } = values

  return this.matches(/^[-A-Za-z0-9]*$/, formatErrorMessage('vin', { fieldName }))
})

function asyncTestFunction(message: string, testFunction: any) {
  return this.test('asyncTest', message, async (value: any, ctx: TestContext) => {
    const syncSchema = ctx.schema.clone()

    syncSchema.tests = syncSchema.tests.filter((test: any) => test.OPTIONS.name !== 'asyncTest')

    try {
      syncSchema.validateSync(value)
    } catch (e: any) {
      return Promise.resolve(e.message)
    }

    if (process?.env?.NODE_ENV === 'test') {
      return Promise.resolve(true)
    }

    return testFunction(value, ctx)
  })
}

addMethod(string, 'asyncTest', asyncTestFunction)
addMethod(date, 'asyncTest', asyncTestFunction)
addMethod(number, 'asyncTest', asyncTestFunction)
addMethod(mixed, 'asyncTest', asyncTestFunction)

export const cachePreviousValidation = (initialValue, validationFunction) => {
  let previousValue = initialValue
  let previousResult = null

  const validate = async (value) => {
    if (value === previousValue) {
      return previousResult
    }

    const result = await validationFunction(value)

    previousValue = value
    previousResult = result

    return result
  }

  return validate
}

export const isEngineNumberUniqueQuery = async (value: string) => {
  try {
    const res = await axiosClient.post(paths.formsEngineNumber(), { engine_number: value })
    return res.status === 200
  } catch (error) {
    return false
  }
}

export const isVinUniqueQuery = async (value: string) => {
  try {
    const res = await axiosClient.post(paths.formsVin(), { vin: value })
    return res.status === 200
  } catch (error) {
    return false
  }
}

declare module 'yup' {
  interface StringSchema {
    name(values: any): StringSchema
    text(values: any): StringSchema
    model(values: any): StringSchema
    charsNumbers(values: any): StringSchema
    charsNumbersSpaces(values: any): StringSchema
    phoneNumber(values: any): StringSchema
    vin(values: any): StringSchema
    tyreSizeFormat(values: any): StringSchema
    pinFormat(values: any): StringSchema
    // pinNumbersOnly(values: any): StringSchema
    asyncTest(message: string, testFunction: any): StringSchema
  }

  interface DateSchema {
    rangeYear(values: any): DateSchema
  }

  interface NumberSchema {
    rangeNumber(values: any): StringSchema
    asyncTest(message: string, testFunction: any): NumberSchema
  }

  interface MixedSchema {
    maxFileSize(values: any): MixedSchema
    asyncTest(message: string, testFunction: any): MixedSchema
  }
}
