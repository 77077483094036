import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const PersonalInfoValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Name' }))
    .min(2, formatErrorMessage('minCharacters', { fieldName: 'Name', value: 2 }))
    .max(
      50,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Name',
        value: 50,
      }),
    )
    .name({ fieldName: 'Name' }),

  phone: Yup.string()
    .trim()
    .required(formatErrorMessage('required', { fieldName: 'Phone number' }))
    .phoneNumber({ fieldName: 'Phone number' }),
})
