import { FormikInput, FormikSelect, Heading2, Row } from '@mvr/ui'
import { useFormikContext } from 'formik'
import { arrayToOptions } from 'utils/data'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

type EntryInfoPostProps = {
  dictionaries: any
}

export const EntryInfoPost = ({ dictionaries }: EntryInfoPostProps) => {
  const { setFieldValue, values } = useFormikContext<any>()
  const { centers } = dictionaries

  const handleChangeCenter = (value: string) => {
    const center = centers.find((center: any) => center.uuid === value)

    if (!value || !center) {
      setFieldValue('verification_center_uuid_address', undefined)
      return
    }

    const address = `${center?.address || ''} ${center.city || ''} ${center.country.name || ''}`
    setFieldValue('verification_center_uuid_address', address)
  }

  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Entry Info</Heading2>
      </CardHeading>

      <InputRow>
        <FormikSelect
          name='verification_center_uuid'
          placeholder='Verification Centre'
          options={arrayToOptions(centers)}
          onChange={handleChangeCenter}
          disabled={centers.length === 1}
          searchable
          required
        />

        {values.verification_center_uuid && (
          <FormikInput
            disabled
            name='verification_center_uuid_address'
            placeholder='Verification Centre Address'
            autoComplete='off'
          />
        )}
      </InputRow>

      <Row>
        <FormikInput
          name='registration_number'
          maxLength={11}
          placeholder='Registration Number'
          hint='Default (not special or personalized) registration number assigned to the vehicle'
          onChange={(value: string) => setFieldValue('registration_number', value.toUpperCase())}
          autoComplete='off'
          required
        />
      </Row>

      <InputRow>
        <FormikInput
          name='owner'
          maxLength={160}
          placeholder='Owner'
          hint='Body of Persons Name or Natural Persons Name and Surname'
          autoComplete='off'
          required
        />

        <FormikInput
          name='owners_phone'
          placeholder="Owner's Phone"
          hint='Phone number in 0XXXXXXXXX or +256XXXXXXXXX format'
          autoComplete='off'
          required
        />
      </InputRow>
    </FormContainer>
  )
}
