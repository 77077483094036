import { LogoMVV, useMedia } from '@mvr/ui'
import { Outlet } from 'react-router-dom'

import { useAlert } from 'services'

import { LoginContainer, LoginContent, LogoWrapper } from './LoginPage.styles'

export const LoginPage = (): JSX.Element => {
  const { renderAlert } = useAlert()
  const { isMobile } = useMedia()

  return (
    <LoginContainer>
      <LogoWrapper>
        <LogoMVV width={isMobile ? 137.14 : 205.71} height={isMobile ? 80 : 120} />
      </LogoWrapper>
      <LoginContent>
        <Outlet />
      </LoginContent>
      {renderAlert({
        style: { marginTop: '3rem' },
      })}
    </LoginContainer>
  )
}
