import { isAxiosError } from 'axios'
import { ROUTES } from 'constants/routes'
import { StatusCodes } from 'constants/statusCodes'
import { FormikErrors } from 'formik'
import { useNavigate } from 'react-router-dom'

import { paths } from 'api'
import { useFetch } from 'hooks'

interface FormStateProps {
  email: string
}

type ErrorsT = (errors: FormikErrors<FormStateProps>) => void

const useRestPinCode = () => {
  const { usePostMutation } = useFetch()
  const resetPinMutation = usePostMutation([paths.resetPin()])
  const isLoading = resetPinMutation.isLoading
  const navigate = useNavigate()
  const initialValues = {
    email: '',
  }

  const onSubmit = (formData: FormStateProps, setErrors: ErrorsT) => {
    resetPinMutation.mutate(
      { path: paths.resetPin(), body: formData },
      {
        onSuccess: () => navigate(ROUTES.resetPinSuccess),
        onError: (error) => {
          if (isAxiosError(error) && error.response?.status === StatusCodes.NOT_FOUND) {
            return setErrors({ email: 'The provided email was not found in the system' })
          }
          setErrors({ email: 'Some error occurred, please try again' })
        },
      },
    )
  }

  return {
    initialValues,
    isLoading,
    onSubmit,
  }
}

export default useRestPinCode
