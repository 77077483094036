import styled from 'styled-components'

import { FormContainer } from '../../Form.styles'

export const TextareaWrapper = styled.div`
  margin-top: 2rem;
`

export const StyledFormContainer = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      margin-top: 1.5rem
    }
  `}
`

export const SignContainer = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.primaryDisabled};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`
