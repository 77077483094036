import { AxiosError, isAxiosError } from 'axios'
import { ROUTES } from 'constants/routes'
import { StatusCodes } from 'constants/statusCodes'
import { FormikErrors } from 'formik'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAlert, useAuth } from 'services'

interface LoginCodeFormProps {
  code: string
  pin_code: string
}

type ErrorsT = (errors: FormikErrors<LoginCodeFormProps>) => void

const useLoginByCode = () => {
  const { login, loginMutation } = useAuth()
  const { showAlert, hideAlert } = useAlert()
  const navigate = useNavigate()
  const isLoading = loginMutation.isLoading
  const initialValues = {
    code: '',
    pin_code: '',
  }

  useEffect(
    () => () => {
      hideAlert()
    },
    [],
  )

  const handleChange = () => {
    hideAlert()
  }

  const onSuccess = () => navigate(ROUTES.home)

  const onError = (error: AxiosError, setErrors: ErrorsT) => {
    const isWrongCredentials =
      error.response?.status === StatusCodes.UNAUTHORIZED || error.response?.status === StatusCodes.FORBIDDEN

    if (isAxiosError(error) && isWrongCredentials) {
      return setErrors({ code: 'These credentials do not match our records.' })
    }
    showAlert({ type: 'error', text: 'Some error occurred.' })
  }

  const onSubmit = (data: LoginCodeFormProps, setErrors: ErrorsT) => {
    const onSubmitError = (error: AxiosError) => onError(error, setErrors)

    login(data, onSuccess, onSubmitError)
  }

  return {
    initialValues,
    isLoading,
    onSubmit,
    handleChange,
  }
}

export default useLoginByCode
