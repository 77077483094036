import axios, { AxiosHeaderValue, AxiosRequestHeaders, HeadersDefaults } from 'axios'

import { IAuthorization } from 'common'

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
})

type ICustomHeaders = {
  'Content-Type': AxiosHeaderValue
  Accept: AxiosHeaderValue
  Authorization: AxiosHeaderValue
  [key: string]: AxiosHeaderValue
}

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
} as ICustomHeaders & HeadersDefaults

// Adding Authorization header for all requests
axiosClient.interceptors.request.use(
  (config) => {
    const localStorageValue = localStorage.getItem('authorization')
    const parsedStorageValue: IAuthorization | null = localStorageValue ? JSON.parse(localStorageValue) : null
    const token = parsedStorageValue?.token ?? null

    if (token) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
