import { Button, FormikInput, Heading2, Form as PersonalInfoForm, useMedia } from '@mvr/ui'

import { FieldsWrapper, FormButton, Heading } from '../../Profile.styles'
import { SuccessModal } from '../index'
import { PersonalInfoValidationSchema } from './PersonalInfo.validation'
import { usePersonalInfo } from './usePersonalInfo'

const PersonalInfo = () => {
  const { isModalOpen, onModalClose, formValues, handleSubmit } = usePersonalInfo()
  const { isMobile } = useMedia()

  return (
    <div>
      <Heading>
        <Heading2>Personal information</Heading2>
      </Heading>

      <PersonalInfoForm
        onSubmit={(formData) => handleSubmit(formData)}
        validationSchema={PersonalInfoValidationSchema}
        initialValues={formValues}
        enableReinitialize
      >
        <FieldsWrapper>
          <FormikInput name='email' placeholder='Email' autoComplete='off' disabled required />

          <FormikInput name='name' placeholder='Name' autoComplete='off' required />

          <FormikInput name='phone' placeholder='Phone' autoComplete='off' required />
        </FieldsWrapper>

        <FormButton>
          <Button type='submit' fullWidth={isMobile}>
            Save changes
          </Button>
        </FormButton>
      </PersonalInfoForm>

      <SuccessModal isOpen={isModalOpen} onClose={onModalClose} />
    </div>
  )
}

export default PersonalInfo
