import { FormikInput, Heading2, Row } from '@mvr/ui'
import { CardHeading, FormContainer } from '../../../Form.styles'

export const VehicleHistory = () => {
  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Vehicle History</Heading2>
      </CardHeading>
      <Row>
        <FormikInput type='number' name='mileage' placeholder='Kilometres/Mileage' required />
      </Row>
    </FormContainer>
  )
}
