import { formatErrorMessage } from 'locale'
import * as Yup from 'yup'

export const LastStepFooterValidationSchema = Yup.object().shape({
  comment: Yup.string()
    .trim()
    .test(
      'comments',
      formatErrorMessage('minCharacters', { fieldName: 'Comments', value: 2 }),
      (value: string) => !value || value.length > 1,
    )
    .max(
      500,
      formatErrorMessage('maxCharacters', {
        fieldName: 'Comments',
        value: 500,
      }),
    ),

  sign: Yup.boolean()
    .required(formatErrorMessage('required', { fieldName: 'Sign' }))
    .oneOf([true], formatErrorMessage('required', { fieldName: 'Sign' })),
})
