import get from 'lodash/get'

type Tree = any

type Flatten = string[]

export const flatKeys = (tree: Tree) => {
  const leaves: Flatten = []

  const walk = (obj: any, root = '') => {
    for (const node in obj) {
      const path = `${root}${root ? '.' : ''}${node}`
      const value = obj[node]

      if (typeof value === 'object') {
        walk(value, path)
      } else {
        leaves.push(path)
      }
    }
  }

  walk(tree)

  return leaves
}

export const flatObject = (tree: Tree) => {
  const acc: any = {}

  flatKeys(tree).forEach((key) => {
    acc[key] = get(tree, key)
  })

  return acc
}
